import React, { type ReactNode, useMemo } from 'react';

import {
	type AIEventsInstrumentationConfig,
	AIEventsInstrumentationContext,
	type AIEventsInstrumentationContextType,
} from '../context';
import { EventDispatcherSources } from '../events';
import { useAIInstrumentationSetup } from '../hooks';

export const defaultConfigValues: Pick<
	AIEventsInstrumentationConfig,
	| 'actionSubjectId'
	| 'isAIFeature'
	| 'eventDispatcherSource'
	| 'skipAISessionValidation'
	| 'useDefaultGASv3EventSchema'
> = {
	isAIFeature: 1,
	actionSubjectId: 'client',
	eventDispatcherSource: EventDispatcherSources.JSLib,
	skipAISessionValidation: false,
	useDefaultGASv3EventSchema: false,
};

const validateAIEventsConfig = (config: AIEventsInstrumentationConfig) => {
	if (!config.aiFeatureName.match(/^[a-z][A-Za-z]*$/)) {
		// eslint-disable-next-line no-console
		console.error('[@atlassian/ai-analytics]: please set aiFeatureName in camelCase');
	}
};

type Props = {
	children: ReactNode;
	config: AIEventsInstrumentationConfig;
};

export const AIEventsInstrumentationProvider = ({ children, config }: Props) => {
	const {
		refreshSingleInstrumentationID,
		getSingleInstrumentationID,
		setAISessionState,
		getAISessionState,
	} = useAIInstrumentationSetup(config.singleInstrumentationID);

	const contextValue = useMemo<AIEventsInstrumentationContextType>(() => {
		validateAIEventsConfig(config);
		return {
			config: {
				...defaultConfigValues,
				...config,
				product: config.product.toLowerCase(),
			},
			refreshSingleInstrumentationID,
			getSingleInstrumentationID,
			setAISessionState,
			getAISessionState,
		};
	}, [
		config,
		refreshSingleInstrumentationID,
		getSingleInstrumentationID,
		setAISessionState,
		getAISessionState,
	]);

	return (
		<AIEventsInstrumentationContext.Provider value={contextValue}>
			{children}
		</AIEventsInstrumentationContext.Provider>
	);
};
