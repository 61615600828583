import { useCallback, useRef, useState, useSyncExternalStore } from 'react';

import deepEqual from 'fast-deep-equal';
import type { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

export type ObservableStatefulInput<TInputs extends Readonly<any[]>, TState> = {
	inputs: [...TInputs];
	state: TState;
};

export function useStatefulObservableLazyEffect<TState, TInputs extends Readonly<any[]>>(
	onObservableInit: (
		input$: Observable<ObservableStatefulInput<TInputs, TState>>,
	) => Observable<TState>,
	initialState: TState,
): [TState, (nextState: ObservableStatefulInput<TInputs, TState>) => void] {
	const [inputSubject$] = useState(() => new Subject<ObservableStatefulInput<TInputs, TState>>());
	const [observable$] = useState(() => onObservableInit(inputSubject$));
	const stateSnapshot = useRef(initialState);

	const subscribe = useCallback(
		(handleStoreChange: VoidFunction) => {
			const subscription = observable$.subscribe({
				next: (nextState) => {
					if (!deepEqual(nextState, stateSnapshot.current)) {
						stateSnapshot.current = nextState;
						handleStoreChange();
					}
				},
				// eslint-disable-next-line no-console
				error: (err) => console.error(err),
				complete: () => inputSubject$.complete(),
			});
			return () => subscription.unsubscribe();
		},
		[inputSubject$, observable$],
	);

	const getSnapshot = useCallback(() => {
		return stateSnapshot.current;
	}, [stateSnapshot]);

	const callback = useCallback(
		(nextState: ObservableStatefulInput<TInputs, TState>) => {
			inputSubject$.next(nextState);
		},
		[inputSubject$],
	);

	const state = useSyncExternalStore(subscribe, getSnapshot);
	return [state, callback];
}
